import * as React from 'react'
import '@scss/main.scss'
import { Layout } from '@components/layout'
import { graphql } from 'gatsby'
import { Navigation } from '../components/Navigation'
import Components from '@components/components'
import { Footer } from '@components/Footer'
import { ApartmentSlider } from '@components/ApartmentSlider'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { Booking } from '@components/Booking'
import Helmet from 'react-helmet'
import { Link, useIntl } from 'gatsby-plugin-intl'
import { ApartmentList } from '@components/ApartmentList'
import Slider from 'react-slick'
import ScrollAnimation from 'react-animate-on-scroll'
import { StaticImage } from 'gatsby-plugin-image'

const settings = {
  arrows: true,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  rows: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
}

const IndexPage = (data: any) => {
  const intl = useIntl()

  return (
    <Layout data={data}>
      <Helmet>
        <title>{data.pageContext.data.name} | Oldschool</title>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
        />
        <script
          src="https://static.clickskeks.at/1f/33/1f3388bb-a92e-4c3b-9ac1-ffc507870f5d/bundle.js"
          type="application/javascript"
        ></script>
        <script
          data-cookieconsent="preferences"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EGC1QZ1FCF"
        ></script>
      </Helmet>
      <div className="pb-5 pt-5">
        {data.pageContext.data.youtubeId && (
          <div className="apartment-hero video pt-lg-5 mt-5">
            <div className="container apartment-container position-relative">
              <h1>{data.pageContext.data.name}</h1>
              <div className="embed-responsive embed-responsive-16by9 mt-5">
                <iframe
                  className="embed-responsive-item mt-0"
                  allowFullScreen
                  src={
                    'https://www.youtube-nocookie.com/embed/' +
                    data.pageContext.data.youtubeId +
                    '?rel=0&amp;autoplay=1&version=3&loop=1&start=8&mute=1&controls=0&playlist=' +
                    data.pageContext.data.youtubeId
                  }
                ></iframe>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="row">
            <div className="col-md-8 pr-lg-5 paragraph-text">
              {data.pageContext.data.text && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(
                      JSON.parse(data.pageContext.data.text.raw),
                    ),
                  }}
                ></div>
              )}
              <ApartmentSlider
                name={data.pageContext.data.name}
                data={data.pageContext.data.images}
              />
              <h3 className="mt-3 mb-0">{intl.formatMessage({ id: 'floorplan' })}</h3>
              <div className="row">
                {data.pageContext.data.groundplans.map((image) => (
                  <div className="col-lg-6">
                    <img
                      key={'apartement-groundplan' + image.fixed.src}
                      className="apartment-groundplan img-fluid mt-4"
                      src={image.fixed.src}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4 paragraph-text no-space mt-3 mt-lg-0">
              <strong>{intl.formatMessage({ id: 'size' })}</strong>
              <div className="mb-4">{data.pageContext.data.size}</div>
              <strong>{intl.formatMessage({ id: 'persons' })}</strong>
              <div className="mb-4">{data.pageContext.data.persons}</div>
              <strong>{intl.formatMessage({ id: 'equipments' })}</strong>
              {data.pageContext.data.equipments && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(
                      JSON.parse(data.pageContext.data.equipments.raw),
                    ),
                  }}
                ></div>
              )}
              <a
                href={"https://onepagebooking.com/oldschool-soell?module=public&ratetype=bar&lang=" + intl.locale }
                className="nav-cta"
                target="_blank"
              >
                <p className="nav-cta-title pb-3">
                  {intl.formatMessage({ id: 'book' })}
                </p>
                <StaticImage alt="book" src="../../images/orange-stroke.png" />
              </a>
            </div>
          </div>

          {Array.isArray(data.pageContext.data.content) &&
            data.pageContext.data.content.length > 0 && (
              <Slider className="apartment-overview-slider" {...settings}>
                {data.pageContext.data.content[0].apartments
                  .filter((apartment) => {
                    return apartment.slug !== data.pageContext.data.slug
                  })
                  .map((apartment) => (
                    <Link
                      to={apartment.slug}
                      key={apartment.name}
                      className="apartment-overview-entry mt-4"
                    >
                      <ScrollAnimation animateIn="fadeIn">
                        <h2 className="apartment-overview-entry-name">
                          {apartment.name}
                        </h2>
                        <div className="apartment-overview-entry-teaser">
                          {apartment.teaser}
                        </div>
                        {apartment.images && apartment.images.length > 0 && (
                          <img
                            className="img-fluid"
                            src={apartment.images[0].fixed.src}
                          />
                        )}{' '}
                      </ScrollAnimation>
                    </Link>
                  ))}
              </Slider>
            )}
        </div>
      </div>
      <Footer key={data.pageContext.id} data={data.pageContext.navigation} />
    </Layout>
  )
}

export default IndexPage
